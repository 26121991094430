export default function getCookie(name) {
  if (!document.cookie) {
    return null
  }

  const cookies = document.cookie.split(";").map((c) => c.trim())
  const cookie = cookies.find((c) => c.indexOf(`${name}=`) === 0)
  if (!cookie) {
    return null
  }

  return decodeURIComponent(`${cookie.substring(name.length + 1)}`)
}
