import * as Sentry from "@sentry/browser"
import { CaptureConsole } from "@sentry/integrations"
import gitProperties from "../../git.properties.json"

const dsnConfig = document.querySelector('meta[name~="machtfit-sentry-dsn"]')
const environmentConfig = document.querySelector('meta[name~="machtfit-sentry-environment"]')
const dsn = dsnConfig && dsnConfig.getAttribute("content")
const environment = environmentConfig && environmentConfig.getAttribute("content")

function initSentry() {
  if (!dsn) {
    console.warn("No sentry DSN URL configured. Not initializing sentry.")
    return
  }

  const release = gitProperties?.git?.commit?.id

  if (release) {
    console.log(`Running release ${release}.`)
  } else {
    console.warn("No release information. Initializing Sentry without a release.")
  }

  Sentry.init({
    dsn,
    release,
    integrations: [new CaptureConsole({ levels: ["error"] })],
    environment: environment,
  })

  window.mfSentry = Sentry
}

initSentry()
