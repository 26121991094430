/*
 Helper functions mostly intended for replacing jQuery functions
 http://youmightnotneedjquery.com/
*/

export function documentReady(fn) {
  if (
    document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading"
  ) {
    fn()
  } else {
    document.addEventListener("DOMContentLoaded", fn)
  }
}

export function isHidden(element) {
  // https://stackoverflow.com/questions/19669786/
  return element.offsetParent === null
}

export function hasClass(element, targetClass) {
  if (!element) {
    return false
  }

  if (typeof element.hasAttribute !== "function") {
    return false
  }

  if (!element.hasAttribute("class")) {
    return false
  }

  return element.getAttribute("class").split(" ").indexOf(targetClass) >= 0
}
